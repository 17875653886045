<template>
  <div class="container">
    <TopHeader v-if="data.status === 4 || data.status === 5" title="未通过"></TopHeader>
    <TopHeader v-if="data.status === 6 || data.status === 7 || data.status === 8" title="失效"></TopHeader>
    <TopHeader v-if="data.status === 3" title="审批通过"></TopHeader>
    <TopHeader v-if="data.status === 1 || data.status === 2" title="待审批"></TopHeader>
    <div class="main-container">
      <div class="main-content p10">
        <div class="main-items">
          <div class="items-title-content">
            <div class="title-left">
              <div class="title-left-remark">来</div>
              <div class="title-left-name">来访信息</div>
            </div>
            <div class="title-right" v-if="data.status === 6 || data.status === 7 || data.status === 8">
              <img class="title-right-img" src="../../assets/images/inviteFailure.png" alt="">
            </div>
            <div class="title-right" v-if="data.status === 4 || data.status === 5">
              <img class="title-right-img" src="../../assets/images/inviteNotPass.png" alt="">
            </div>
          </div>
          <CommonBorder></CommonBorder>
          <div class="items-content">
            <div class="items-content-title">被访人姓名</div>
            <div class="items-content-info">{{ data.callName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">被访人部门</div>
            <div class="items-content-info">{{ data.departmentName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访时间</div>
            <div class="items-content-info">{{ data.start }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">离开时间</div>
            <div class="items-content-info">{{ data.end }}</div>
          </div>
        </div>
      </div>
      <div class="main-content p5">
        <div class="main-items">
          <div class="items-title-content">
            <div class="title-left">
              <div class="title-left-remark">访</div>
              <div class="title-left-name">访客信息</div>
            </div>
            <div class="title-right" v-if="data.status === 6 || data.status === 7 || data.status === 8">
              <img class="title-right-img" src="../../assets/images/inviteFailure.png" alt="">
            </div>
            <div class="title-right" v-if="data.status === 4 || data.status === 5">
              <img class="title-right-img" src="../../assets/images/inviteNotPass.png" alt="">
            </div>
          </div>
          <CommonBorder></CommonBorder>
          <div class="items-content">
            <div class="items-content-title">访客姓名</div>
            <div class="items-content-info">{{ data.visitName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访单位</div>
            <div class="items-content-info">{{ data.unit }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访事由</div>
            <div class="items-content-info">{{ reasonMap[data.reason] }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">手机号码</div>
            <div class="items-content-info">{{ data.phone }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">身份证号码</div>
            <div class="items-content-info">{{ data.card }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">随行人数</div>
            <div class="items-content-info">
              <span v-if="data.num === 0">1至5人</span>
              <span v-if="data.num === 1">大于5人</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container" v-if="data.status === 3  &&  verify2Id === data.verify1Id">
        <div class="btn-content">
          <van-button class="btn" style="background: #fff;color: #FA5050;border-color: #FA5050;" block  @click="handleEditInvite(data)">修改预约</van-button>
          <van-button class="btn" style="background: #FA5050;color: #fff;border-color: #FA5050;" block  @click.stop="handleCancelInvite(data.id, 2)">取消预约</van-button>
        </div>
      </div>
    </div>
    <div>
      <van-dialog v-model="showCancel" title="提示" show-cancel-button confirm-button-color="#FA5050" confirm-button-text="确定" cancel-button-text="取消" cancel-button-color="#999999" @cancel="handleCancel" @confirm="handleConfirm">
        <div class="confirm-dialog">请确认是否"取消预约"?</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { auditVisitAPI } from "@/api/invite";
import { Toast } from "vant";
import TopHeader from '@/components/topHeader/index.vue'
import CommonBorder from '@/components/commonBorder/index.vue'

export default {
  name:"InviteDetail",
  components: {
    TopHeader,
    CommonBorder,
  },
  data() {
    return {
      cancel,
      data: {},
      verify2Id: "",
      reasonMap: {
        0: "开会",
        1: "面试",
        2: "拜访",
        3: "合作",
        4: "洽谈",
        5: "访友",
        6: "推销",
        7: "其他",
      },
      showCancel: false,
      id: null,
      status: null
    };
  },
  created() {
    this.data = JSON.parse(this.$route.query.item);
    this.verify2Id = JSON.parse(sessionStorage.getItem("personInfo")).id;
  },
  methods: {
    toBack() {
      this.$router.back();
    },

    handleEditInvite(item) {
      this.$router.push({
        name: "InviteEdit",
        query: { item: JSON.stringify(item) },
      });
    },

    handleCancelInvite(id, status) {
      this.showCancel = true;
      this.status = status
      this.id = id
    },

    async handleConfirm() {
      const res = await auditVisitAPI(this.status, this.id)
      if (res.code === 0) {
        Toast.success({ message: "已取消!", });
        this.$router.push({ name: "InviteList" });
      }
    },

    handleCancel(){
      this.showCancel = false;
    }
  },
};
</script>

<style lang='scss' scoped>
.container{
  background: #f7f7f7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .main-container{
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    .main-content{
      box-sizing: border-box;
      .main-items{
        background: #fff;
        border-radius: 10px;
        .items-title-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px;
          box-sizing: border-box;
          padding: 0px 16px;
          justify-content: space-between;
          .title-left{
            display: flex;
            flex-direction: row;
            .title-left-remark{
              background: #C7000B;
              color: #fff;
              width: 21px;
              height: 21px;
              font-size: 12px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              margin-right: 4px;
            }
            .title-left-name{
              color: #333;
              font-weight: 700;
            }
          }
          .title-right{
            margin-right: -16px;
            .title-right-img{
              height: 49px;
              width:65px;
              vertical-align: bottom;
            }
          }
        }
        .items-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 44px;
          box-sizing: border-box;
          padding: 0px 16px;
          .items-content-title{
            color: #333;
          }
          .items-content-info{
            color: #666;
          }
        }
      }
    }
    .p10{
      padding: 10px 16px;
    }
    .p5{
      padding: 5px 16px;
    }
    .btn-container{
      width: 100%;
      box-sizing: border-box;
      padding: 0 16px;
      margin-top: 25px;
      .btn-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        .btn{
          width:164px;
          font-size: 18px;
          border-radius: 4px;
        }
      }
    }
  }
}

.confirm-dialog{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #666;
  margin-bottom: 10px;
}

::v-deep .van-dialog{
  height: 160px;
}

::v-deep .van-dialog__header{
  color: #333 !important;
}
</style>
